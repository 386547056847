
import { Link } from 'react-router-dom';
import Logo from '../components/Logo/Logo';
import Button from '../components/shared/Button/Button';
import InfoBar from '../components/InfoBar/InfoBar';

import './page.scss'

function Main() {
    return (
        <div className="app main-menu min-vh-100 main-page">
            <div className='container-fluid'>
                <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
                    <Logo />
                    <div className='d-flex justify-content-evenly m-4'>
                        <Link to="/category">
                            <Button type="danger" >Play</Button>
                        </Link>
                        <Link to="/options">
                            <Button type="warning" >Setting</Button>
                        </Link>
                    </div>
                </div>
            </div>
           <InfoBar />
        </div>
    );
}

export default Main