import { useContext, useEffect } from "react";
import useSound from "use-sound";
import Actor from "../components/Actor/Actor";
import GameKeyboard from "../components/GameKeyboard/GameKeyboard";
import GameStat from "../components/GameStat/GameStat";
import GuessArea from "../components/GuessArea/GuessArea";
import PlayHeader from "../components/PlayHeader/PlayHeader";
import PlayStore, { PlayAction, PlayContext } from "../stores/PlayStore";
import oYeah from "../asset/sound/o_yeah.mp3"
import { GlobalContext } from "../stores/GlobalStore";
import { useParams } from "react-router-dom";
import { shuffle } from "../utils/generator";
import usePreviousValue from "../hooks/usePreviousValue";
import { useState } from "react";
import LostGameBanner from "../components/LostGameBanner/LostGameBanner";
import useDialogSpeech from "../hooks/useDialogSpeech";

import './page.scss';

const Play = () => {
    const [lostView, setLostView] = useState(false)
    const [playGood, playBad] = useDialogSpeech()
    let { cat } = useParams();
    const [PlayWinSound] = useSound(oYeah)
    const [PlayStore, dispatch] = useContext(PlayContext)
    const [{ sound }] = useContext(GlobalContext)
    const { listOfItems, playedWords, activeItem, wrongInputCount } = PlayStore;
    useEffect(() => {
        var data = require(`../asset/content/${cat.toLowerCase()}.json`)
        const randomize = shuffle(data.map(v => v.toUpperCase()))
        dispatch({ type: PlayAction.SET_LIST_OF_ITEMS, payload: randomize })
    }, [])
    useEffect(() => {
        if (wrongInputCount >= 4) {
            LostGame();
        } else {
            if(Math.floor(Math.random() * 2) && wrongInputCount) playBad();
        }
    }, [wrongInputCount]);
    useEffect(() => {
        if (playedWords.at(-1)) checkWordForLetter(playedWords.at(-1));
    }, [playedWords]);
    const checkWordForLetter = (key) => {
        let letterFound = false;
        const item = listOfItems[activeItem];
        if (item.indexOf(key) >= 0) letterFound = true
        if (!letterFound) {
            checkForHangman()
        } else {
            if(Math.floor(Math.random() * 2)) playGood();
            /* dispatch({ type: PlayAction.ADD_PLAYED_WORD, payload: { key: key } }) */
        }
        checkWinCondition()
    }
    const checkForHangman = () => {
        dispatch({ type: PlayAction.INCREMENT_WRONG_COUNT });
    }
    const checkWinCondition = () => {
        let item = listOfItems[activeItem];
        item = item.replace(/\s/g, '')
        const isAllWordClicked = Array.from(item).every(e => playedWords.includes(e))
        if (isAllWordClicked) {
            WinGame();
        } else {
        }
    }
    const prev = usePreviousValue(checkWinCondition);
    function LostGame() {
        dispatch({ type: PlayAction.REVEAL_ANSWER_YES })
        setLostView(true)
    }
    function WinGame() {
        if (sound) PlayWinSound()
        setTimeout(() => {
            dispatch({ type: PlayAction.RESET_PLAYED_WORD })
            dispatch({ type: PlayAction.UPDATE_ACTIVE_ITEM })
            dispatch({ type: PlayAction.RESET_WRONG_COUNT })
        }, 1000);
    }
    const onRestart = () => {
        dispatch({ type: PlayAction.REPLAY_GAME, payload: { category: cat } })
        setLostView(false)
    }
    return (
        <div className="container-fluid vh-100 play-page">
            <div className="row justify-content-center align-content-center">
                <div className="col-12"><PlayHeader enableHome={false}/></div>
            </div>
            <div className="row justify-content-center">
                <div className="row">
                    <div className="col-12"><GameStat /></div>
                </div>
                <div className="row justify-content-center">
                    <div className="container-fluid">
                        <div className="align-items-sm-center row" style={{ minHeight: "50vh" }}>
                            <div className="col-sm-3 offset-sm-1"><Actor /></div>
                            <div className="col-sm-7">
                                <div className="d-flex justify-content-center align-items-center">
                                    <GuessArea />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        {lostView && <LostGameBanner onRestart={onRestart} />}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="fixed-bottom pb-1">
                        {!lostView && <GameKeyboard />}
                    </div>
                </div>
            </div>
        </div>
    );
}
const PlayWithStore = () => (
    <PlayStore>
        <Play />
    </PlayStore>
)
export default PlayWithStore;