import { IoReloadCircle } from "react-icons/io5";

const LostGameBanner = ({ onRestart }) => {

    return (
        <div className="d-flex flex-column pb-3">
            <button className="btn d-flex justify-content-center align-content-center align-items-center" onClick={onRestart}>
                <div className="m-2">
                    <IoReloadCircle size={"calc(1.625rem + 4.5vw)"} fill="#a30500" />
                </div>
                <h1 className="display-1 m-2">Play Again ?</h1>
            </button>
        </div>
    );
}

export default LostGameBanner;