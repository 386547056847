import ModalCreator from "../../modals/ModalCreator";
const InfoBar = () => {
    return (<div>
        <div className='position-fixed bottom-0 end-0 m-1'>
            <button type="button" data-bs-toggle="modal" data-bs-target="#creditModal" >Extras</button>
        </div>
        <ModalCreator id="creditModal" header="Extras">
        <div class="d-flex align-items-center">
            <a rel="noopener" href='https://play.google.com/store/apps/details?id=com.gamelette.moodyman.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="img-fluid" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            <a rel="noopener" className="m-2" href='http://www.amazon.com/gp/product/B0B925TBWZ/ref=mas_pm_moody_man'><img className="img-fluid" alt='Get it on Amazon appstore' src='https://images-na.ssl-images-amazon.com/images/G/01/mobile-apps/devportal2/res/images/amazon-appstore-badge-english-black.png'/></a>
        </div>
            <h5>Credits</h5>
            <ul className="font-normal">
                <li>
                    <a target="_blank" rel="noopener" href="https://www.regardingtech.com">Regarding Tech</a>
                </li>
                <li>
                    <a rel="noreferrer nofollow" target="_blank" href="https://www.zapsplat.com">Additional sound effects from Zapsplat</a>
                </li>
                <li>
                    <a rel="noreferrer nofollow" target="_blank" href="https://www.vecteezy.com/free-vector/background">Added Background Vectors by Vecteezy</a>
                </li>
                <li>
                    <a rel="noreferrer nofollow" target="_blank"  href="https://15.ai/">TTS Service by 15.ai</a>
                </li>
            </ul>
            <div className="d-flex flex-column">
                <small class="fs-7 mb-0 font-normal text-muted">Google Play and the Google Play logo are trademarks of Google LLC.</small>
                <small class="fs-7 font-normal text-muted">Amazon, Amazon Appstore and their associated logos are trademarks of Amazon.com, Inc. or its affiliates.</small>
            </div>
        </ModalCreator>
    </div>);
}
export default InfoBar;