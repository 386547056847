import { Modal } from "bootstrap"
import { useRef } from "react"

const ModalCreator = ({ id, header, children }) => {
  const modalRef = useRef()

  const showModal = () => {
    const modalEle = modalRef.current
    const bsModal = new Modal(modalEle, {
      backdrop: 'static',
      keyboard: false
    })
    bsModal.show()
  }

  const hideModal = () => {
    const modalEle = modalRef.current
    if(modalEle){
      const bsModal = Modal.getInstance(modalEle)
      bsModal.hide()
    }
  }

  return (
    <div className="modal fade" id={id}  data-backdrop="false" ref={modalRef} tabIndex="-1" >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">{header}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalCreator;