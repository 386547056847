import './logo.css'
import logo from '../../asset/img/logo.png'
import logoWebP from '../../asset/img/logo.webp'

const SvgLogo = <svg className='logo' /* viewBox="0 0 960 300" */>
<symbol id="s-text">
    <text text-anchor="middle" x="50%" y="80%">MoodyMan</text>


</symbol>

<g className="g-ants">
    <use xlinkHref="#s-text" className="text-copy"></use>
    <use xlinkHref="#s-text" className="text-copy"></use>
    <use xlinkHref="#s-text" className="text-copy"></use>
    <use xlinkHref="#s-text" className="text-copy"></use>
    <use xlinkHref="#s-text" className="text-copy"></use>
</g>
</svg>

const Logo = () => {
    return (
        <div className='align-self-center' style={{maxWidth: '750px'}}>
        <picture>
            <source type="image/webp" srcSet={ logoWebP } />
            <source type="image/png" srcSet={ logo } />
            <img src={ logo } className="img-fluid" alt="moody man logo"></img>
        </picture>
        </div>
    );
}

export default Logo;