import { useContext } from 'react';
import { useState } from 'react';
import {
    FloatingMenu,
    MainButton,
    ChildButton,
    Directions
  } from 'react-floating-button-menu';
  import { MdClose, MdAdd, MdFavorite, MdSettings } from 'react-icons/md';
  import { FaVolumeOff, FaVolumeUp } from "react-icons/fa";
import { TbArrowBackUp, TbMusic, TbMusicOff } from "react-icons/tb";
import { TiKeyboard, TiSortAlphabeticallyOutline } from "react-icons/ti";
import { GlobalAction, GlobalContext } from '../stores/GlobalStore';
const SettingIcon = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [{ keyboardLayout, sound, music }, dispatch] = useContext(GlobalContext);
  const IconProps = {
    size: 'calc(1rem + 1vw)',
  }
    return ( 
        <FloatingMenu
    slideSpeed={500}
    direction={Directions.Left}
    spacing={8}
    isOpen={isOpen}
  >
    <MainButton
      iconResting={<MdSettings fill='#fdae26' {...IconProps} />}
      iconActive={<MdClose fill='#fdae26' {...IconProps} />}
      background="#a2000b"
      onClick={() => setIsOpen(!isOpen)}
      size="calc(1rem + 2vmax)"
    />
     <ChildButton
      icon={sound ? <FaVolumeUp  {...IconProps} /> : <FaVolumeOff {...IconProps} />}
      background="#fdae26"
      size="calc(1rem + 2vw)"
      onClick={() => dispatch({ type: GlobalAction.TOGGLE_SOUND })}
      />
    <ChildButton
      icon={music ? <TbMusic  {...IconProps} /> : <TbMusicOff {...IconProps} />}
      background="#fdae26"
      onClick={() => dispatch({ type: GlobalAction.TOGGLE_MUSIC })}
      size="calc(1rem + 2vw)"
      />
    <ChildButton
      icon={keyboardLayout !== 'qwerty-letters' ? <TiSortAlphabeticallyOutline {...IconProps} /> : <TiKeyboard {...IconProps} />}
      background="#fdae26"
      onClick={() => dispatch({ type: GlobalAction.TOGGLE_KEYBOARD_LAYOUT })}
      size="calc(1rem + 2vw)"
    />
  </FloatingMenu>
     );
}
 
export default SettingIcon;