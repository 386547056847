export const layout= {
    'default': [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      '{lock} a s d f g h j k l ; \' {enter}',
      '{shift} z x c v b n m , . / {shift}',
      '.com @ {space}'
    ],
    'shift': [
      '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      '{lock} A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M &lt; &gt; ? {shift}',
      '.com @ {space}'
    ],
    'qwerty-letters': [
        'Q W E R T Y U I O P',
        'A S D F G H J K L',
        'Z X C V B N M 😉',
    ],
    'serial-letters': [
        'A B C D E F G H I J',
        'K L M N O P Q R S',
        'T U V W X Y Z 😉'
    ]
  }