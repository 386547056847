import CategoryButton from "../components/CategoryButton";
import category from "../asset/content/category.json"
import PlayHeader from "../components/PlayHeader/PlayHeader";

import './page.scss'

const Category = () => {
    return (
        <div className="container-fluid min-vh-100 category-page pb-4">
            <div className="row justify-content-center align-content-center">
                <div className="col-12"><PlayHeader enableCategory={false} /></div>
            </div>
            <div className="container" >
                <div className="d-flex justify-content-center">
                    <h1 className="display-1 mt-3 mb-5">Category</h1>
                </div>
                <div className="row align-items-center justify-content-center g-5">
                    {
                        category.map((x) => (<div key={x.name} className="col-md-6 col-lg-4 col-xl-3">
                            <CategoryButton name={x.name} />
                        </div>))
                    }
                </div>
            </div>
        </div>);
}

export default Category;