import { randomNoUpto } from "./generator";

export const charCountInString = (s, c) =>
    {
        let res = 0;
 
        for (let i = 0; i < s.length; i++)
        {
            // checking character in string
            if (s.charAt(i) === c)
            res++;
        }
        return res;
    }

export const findCharNotPlayedYet = (word, playedCharacters) => {
    let charKey = null;
    do {
        charKey = word[randomNoUpto(word.length)];
    } while (playedCharacters.includes(charKey))
    return charKey
}