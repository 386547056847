import { MainButton } from "react-floating-button-menu";
import { BiCategoryAlt } from "react-icons/bi";
import { GoHome } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import SettingIcon from "../SettingIcon";

const IconProps = {
    size: 'calc(1rem + 1vw)',
    fill: "#fdae26"
}

const PlayHeader = ({ enableSetting = true, enableCategory = true, enableHome = true }) => {
    const navigate = useNavigate();

    let Icon = null;
    let goTo = '';
    if (enableCategory) {
        Icon = <BiCategoryAlt {...IconProps} /> 
        goTo = '/category'
    }
    if (enableHome) {
        Icon = <GoHome {...IconProps} /> 
        goTo="/"
    }

    return (
        <div className="container-fluid p-0">
            <div className="row">
                <div className="col-3">
                    <div className="mt-1">
                        {Icon && <MainButton
                            iconResting={Icon}
                            iconActive={Icon}
                            background="#a2000b"
                            size="calc(1rem + 2vmax)"
                            onClick={() => navigate(goTo, { replace: true })}
                        />}
                    </div>
                </div>
                <div className="col-3 offset-6">
                    {enableSetting &&
                        <div className="float-end mt-1">
                            <SettingIcon />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default PlayHeader;