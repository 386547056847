import { useContext } from "react";
import { TbMusic, TbMusicOff, TbVolumeOff, TbVolume } from "react-icons/tb";
import { TiKeyboard, TiSortAlphabeticallyOutline } from "react-icons/ti";
import { RiVolumeOffVibrateLine, RiVolumeVibrateLine } from "react-icons/ri";
import { GlobalContext, GlobalAction } from "../stores/GlobalStore";
import bgImage from '../asset/img/cat-bg.jpg'
import { useNavigate } from "react-router-dom";
import Button from "../components/shared/Button/Button";

const mainStyle = {
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
}

const IconProps = {
    size: '3rem',
    style: {
        verticalAlign: 'baseline'
    }
}

const Settings = () => {

    const [{ keyboardLayout, sound, music, vibration }, dispatch] = useContext(GlobalContext)
    const navigate = useNavigate();

    const toggleSound = () => {
        dispatch({ type: GlobalAction.TOGGLE_SOUND })
    }

    const toggleVibration = () => {
        dispatch({ type: GlobalAction.TOGGLE_VIBRATION })
    }

    const toggleMusic = () => {
        dispatch({ type: GlobalAction.TOGGLE_MUSIC })
    }

    const toggleKeyboardLayout = () => {
        dispatch({ type: GlobalAction.TOGGLE_KEYBOARD_LAYOUT })
    }

    return (<div className="vh-100 vw-100" style={mainStyle}>
        <div className="container overflow-hidden h-100">
            <div className="row gy-5 h-100 align-content-center fs-2">
                <div className="col-3 offset-3">Keyboard</div>
                <div className="col-3">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="keyLayoutCheck" onChange={toggleKeyboardLayout} checked={keyboardLayout === 'qwerty-letters'} />
                        <label className="form-check-label" htmlFor="keyLayoutCheck">
                            {keyboardLayout !== 'qwerty-letters' ? <TiSortAlphabeticallyOutline {...IconProps} /> : <TiKeyboard {...IconProps} />}
                        </label>
                    </div>
                </div>
                <div className="offset-3 col-3 d-lg-none">Vibration</div>
                <div className="col-3 d-lg-none">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="vibrationCheck" onChange={toggleVibration} checked={vibration} />
                        <label className="form-check-label" htmlFor="vibrationCheck">
                            {vibration ? <RiVolumeVibrateLine  {...IconProps} /> : <RiVolumeOffVibrateLine {...IconProps} />}
                        </label>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="offset-3 col-3">Sound</div>
                <div className="col-3">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="soundCheck" onChange={toggleSound} checked={sound} />
                        <label className="form-check-label" htmlFor="soundCheck">
                            {sound ? <TbVolume  {...IconProps} /> : <TbVolumeOff {...IconProps} />}
                        </label>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="offset-3 col-3">Music</div>
                <div className="col-3   ">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="musicCheck" onChange={toggleMusic} checked={music} />
                        <label className="form-check-label" htmlFor="musicCheck">
                            {music ? <TbMusic  {...IconProps} /> : <TbMusicOff {...IconProps} />}
                        </label>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-center">
                    <Button onClick={() => navigate("/", {replace: true})} >Back </Button>
                </div>
            </div>
        </div>
    </div>);
}

export default Settings;