import { useContext } from "react";
import { PlayContext } from "../../stores/PlayStore";
import { ImCross } from "react-icons/im";
import activeIcon from "../../asset/img/cancel-active.png"
import activeIconWebP from "../../asset/img/cancel-active.png"
import blankIcon from "../../asset/img/cancel-blank.png"
import blankIconWebP from "../../asset/img/cancel-blank.png"

import useSound from 'use-sound';

import error from '../../asset/sound/error.mp3'
import oo from '../../asset/sound/oo.mp3'
import { useEffect } from "react";
import { GlobalContext } from "../../stores/GlobalStore";

import Slide from 'react-reveal/Slide';
import Shake from 'react-reveal/Shake';
import Flash from 'react-reveal/Flash';

import './game-stat.scss'
import vibrate from "../../utils/vibration";

const GameStat = () => {

    const [playError] = useSound(error)
    const [playOO, { sound: Howler }] = useSound(oo)

    const [PlayStore] = useContext(PlayContext)
    const [{ sound, vibration }] = useContext(GlobalContext)

    const { wrongInputCount, playedWords } = PlayStore;

    useEffect(() => {
        if (sound) {
            if (wrongInputCount > 0 && wrongInputCount <= 3) playError()
            if (wrongInputCount > 3) Howler.fade(0.3, 1, 5000).play();
        }
        if(vibration && wrongInputCount > 0) vibrate([73, 82, 100, 171, 195])
    }, [wrongInputCount])

    return (
        <div className="container p-0">
            <div className="d-flex justify-content-evenly">
                <Slide top>
                    <TestImg activated={wrongInputCount > 0} />
                    <TestImg activated={wrongInputCount > 1} />
                    <TestImg activated={wrongInputCount > 2} />
                    <TestImg activated={wrongInputCount > 3} />
                </Slide>
            </div>
            {/* <PlayedWordDrawer playedWords={playedWords} /> */}
        </div>

    );
}

export default GameStat;

const TestImg = ({ activated }) => (
    <Shake when={activated}>
       <div className="game-stat-icon p-1">

            {
                !activated
                ? (<picture>
                    <source type="image/webp" srcSet={ blankIconWebP } />
                    <source type="image/png" srcSet={ blankIcon } />
                    <img className="img-fluid" src={blankIcon} alt="icon for showing not wrong"/>
                </picture>)
                : (<picture>
                    <source type="image/webp" srcSet={ activeIconWebP } />
                    <source type="image/png" srcSet={ activeIcon } />
                    <img className="img-fluid" src={activeIcon} alt="icon for showing wrong"/>
                </picture>)
            }
        
        {/* <GiCrossedBones className="m-2" size={'5em'} fill={activated ? '#dc3545' : 'grey'} /> */}
        </div>
    </Shake>
)

const WrongIcon = ({ activated }) => (
    <Shake when={activated}>
        <ImCross className="m-2" size={'5em'} fill={activated ? '#dc3545' : 'grey'} />
    </Shake>
)

const PlayedWordDrawer = ({ playedWords }) => {
    return (<div className="d-flex flex-wrap justify-content-center">
        {playedWords.map(v => <Flash> <h4><span className="badge rounded-pill bg-info m-1">{v}</span></h4></Flash>)}
    </div>)
}