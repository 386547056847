import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { layout } from './KeyboardLayout';

import './keyboard.scss';
import { useContext, useEffect, useState } from 'react';
import { PlayAction, PlayContext } from '../../stores/PlayStore';
import { GlobalContext } from '../../stores/GlobalStore';

import { findCharNotPlayedYet } from '../../utils/helpers';

const HelpIcon = '😉'

const GameKeyboard = () => {

    const [hintUsed, setHintUsed] = useState(false);

    const [{ playedWords, listOfItems, activeItem }, dispatch] = useContext(PlayContext)
    const [{ keyboardLayout }] = useContext(GlobalContext)

    useEffect(() => {
      setHintUsed(false)
    }, [activeItem]);

    const canDoHelp = (key) => {
      if(key === HelpIcon && hintUsed) return true
      if(key === HelpIcon) {
        const item = listOfItems?.[activeItem]
        let charKey = findCharNotPlayedYet(item, playedWords)
        if(charKey) {
          setHintUsed(true);
          dispatch({ type: PlayAction.ADD_PLAYED_WORD, payload: { key: charKey } })  
          return true
        }
      }
      return false
    }

    const onKeyPress = (key) => {
      if(playedWords.includes(key))return;
      if(!canDoHelp(key)) {
        dispatch({ type: PlayAction.ADD_PLAYED_WORD, payload: { key: key } })
      }
    }

    const hintClassName = hintUsed ? 'disable-events disable-color' : 'bg-opacity-50 bg-success'

    return (<Keyboard
        layout={layout}
        layoutName={keyboardLayout}
        onKeyPress={onKeyPress}
        buttonTheme={[
            {
              class: "disable-events disable-color",
              buttons: playedWords.toString().replaceAll(',',' ')
            },
            {
              class: hintClassName,
              buttons: HelpIcon
            },
          ]}
    />);
}

export default GameKeyboard;