import React, { createContext, useEffect, useReducer } from 'react'

// Create our inital global state for the application.
const initialState = {
    keyboardLayout: 'serial-letters',
    sound: true,
    music: false,
    vibration: true,
}

export const GlobalAction = {
    TOGGLE_KEYBOARD_LAYOUT: 'TOGGLE_KEYBOARD_LAYOUT',
    TOGGLE_SOUND: 'TOGGLE_SOUND',
    TOGGLE_MUSIC: 'TOGGLE_MUSIC',
    TOGGLE_VIBRATION: 'TOGGLE_VIBRATION',
}

const Reducer = (state, action) => {
    switch (action.type) {
        case GlobalAction.TOGGLE_KEYBOARD_LAYOUT:
            return {
                ...state,
                keyboardLayout: state.keyboardLayout  === 'qwerty-letters' ? 'serial-letters' : 'qwerty-letters'
            }
        case GlobalAction.TOGGLE_SOUND:
            return {
                ...state,
                sound: !state.sound
            }
        case GlobalAction.TOGGLE_MUSIC:
            return {
                ...state,
                music: !state.music
            }
        case GlobalAction.TOGGLE_VIBRATION:
            return {
                ...state,
                vibration: !state.vibration
            }
        default:
            return state
    }
}

/**
 * Create our Store component to be wrapped around the main application.
 */
const GlobalStore = ({ children }) => {
    const localStorage = JSON.parse(window.localStorage.getItem('moody_man_globals')) ?? {};
    const initialStateFinal = {...initialState, ...localStorage }  
    const [state, dispatch] = useReducer(Reducer, initialStateFinal)

    useEffect(() => {
        window.localStorage.setItem('moody_man_globals', JSON.stringify(state))
    }, [state]);

    return (
        <GlobalContext.Provider value={[state, dispatch]}>
            {children}
        </GlobalContext.Provider>
    )
}

export const GlobalContext = createContext()
export default GlobalStore