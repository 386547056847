import { useState } from 'react';
import { useContext, useEffect } from 'react';
import {BsEmojiDizzy, BsEmojiAngry, BsEmojiFrown, BsEmojiExpressionless, BsEmojiNeutral, BsEmojiSmile, BsEmojiLaughing, BsEmojiHeartEyes} from 'react-icons/bs'
import { PlayContext } from '../../stores/PlayStore';
import { charCountInString } from '../../utils/helpers'

const ActorState = {
    6: { icon: BsEmojiDizzy, color: '#495057' },
    5: { icon: BsEmojiAngry, color: "#dc3545" },
    4: { icon: BsEmojiFrown, color: "#ffc107" },
    3: { icon: BsEmojiNeutral, color: "#0d6efd" },
    2: { icon: BsEmojiSmile, color: "#0d6efd" },
    1: { icon: BsEmojiLaughing, color: "#198754" },
    0: { icon: BsEmojiHeartEyes, color: "#198754" }
}

const Actor = () => {

    const [{wrongInputCount, playedWords, listOfItems, activeItem}] = useContext(PlayContext)

    const [actorState, setActorState] = useState(0)

    let Actor = 'span';
    useEffect(() => {
        
        const activeWord = listOfItems?.[activeItem];
        const totalLength = activeWord?.length;
        let totalCount = 0;
        playedWords.forEach((word) => {
            totalCount = totalCount + charCountInString(activeWord, word)
        })
        const percentage = (totalCount/totalLength)*100;
        if(percentage > 70) {
            setActorState(2)
        } else if(percentage > 40) {
            setActorState(1)
        } else {
            setActorState(0)
        }

    }, [playedWords, activeItem])

   Actor = wrongInputCount === 4 ? ActorState[6] : ActorState[wrongInputCount + 2 - actorState];

    return ( 
        <div className="mx-5 mx-lg-3 mx-md-4 mx-sm-1 mx-xl-5 my-3 my-md-5">
            <Actor.icon size={'100%'} fill={Actor.color} />
        </div>
    );
}
 
export default Actor;