import './button.css'

const buttonStyle = {
    warning: "orange",
    danger: "red",
    success: "green",
}

const Button = ({type, children, ...rest}) => {
    return ( 
        <button className={`game-button ${type ? buttonStyle[type] : ''}`} {...rest }>{children}</button>
     );
}
 
export default Button;