import React from 'react';
import './App.css';
import useBgMusic from "./hooks/useBgMusic";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Category from './pages/Category';
import Play from './pages/Play';
import GlobalStore from './stores/GlobalStore';
import Settings from './pages/Settings';
import Main from "./pages/Main";

export const App = () => {
  return (
    <GlobalStore>
      <BrowserRouter>
        <GameRoutes />
      </BrowserRouter>
    </GlobalStore>
  )
}

export default App;

const GameRoutes = () => {
  useBgMusic();
  return (<Routes>
    <Route path="/" element={<Main />} />
    <Route path="options" element={<Settings />} />
    <Route path="category" element={<Category />} />
    <Route path="/play/:cat" element={<Play />} />
  </Routes>);
}
