import { useContext } from "react"
import useSound from "use-sound"
import goodspeech from "../asset/speech/15ai_sb_right.wav"
import wrongspeech from "../asset/speech/15ai_sb_wrong.wav"
import { GlobalContext } from "../stores/GlobalStore"

const GoodSprite = ['sweet', 'genius', 'allright', 'ykyt', 'greatMove', 'marvel']
const BadSprite = ['idiots', 'einstein', 'nhbuddy', 'anyletter', 'spell']

const useDialogSpeech = () => {

    const [{sound}] = useContext(GlobalContext);

    const [playGoodSpeech] = useSound(goodspeech, {
        interrupt: true,
        sprite: {
            sweet: [0, 430],
            genius: [639, 671],
            allright: [1595, 538],
            ykyt: [2348, 791],
            greatMove: [3360, 551],
            marvel: [4158, 576],
        }
    })
    const [playBadSpeech] = useSound(wrongspeech, {
        interrupt: true, sprite: {
            idiots: [0, 1802],
            einstein: [2010, 1245],
            nhbuddy: [3410, 2036],
            anyletter: [5782, 2023],
            spell: [8051, 1724],
        }
    })

    const playGood = () => {
        if(sound)  playGoodSpeech({ id: GoodSprite[Math.floor(Math.random() * 6)]})
    }

    const playBad = () => {
        if(sound) playBadSpeech({ id: BadSprite[Math.floor(Math.random() * 5)]})
    }
    
    return [playGood, playBad];
    
}

export default useDialogSpeech;