import React, { createContext, useReducer } from 'react'
import { fetchRandomizeCategoryItems } from '../api/category'

// Create our inital global state for the application.
const initialState = {
    wrongInputCount: 0,
    listOfItems: [],
    activeItem: 0,
    playedWords: [],
    revealAnswer: false
}

export const PlayAction = {
    REPLAY_GAME: 'REPLAY_GAME',
    SET_LIST_OF_ITEMS: 'SET_LIST_OF_ITEMS',
    INCREMENT_WRONG_COUNT: 'INCREMENT_WRONG_COUNT',
    RESET_WRONG_COUNT: 'RESET_WRONG_COUNT',
    ADD_PLAYED_WORD: 'ADD_PLAYED_WORD',
    RESET_PLAYED_WORD: 'REST_PLAYED_WORD',
    UPDATE_ACTIVE_ITEM: 'UPDATE_ACTIVE_ITEM',
    RESET_ACTIVE_ITEM: 'RESET_ACTIVE_ITEM',
    REVEAL_ANSWER_YES: 'REVEAL_ANSWER_YES',
    REVEAL_ANSWER_NO: 'REVEAL_ANSWER_NO'
}

const Reducer = (state, action) => {
    switch (action.type) {
        case PlayAction.SET_LIST_OF_ITEMS:
            return {
                ...state,
                listOfItems: action.payload
            }
        case PlayAction.INCREMENT_WRONG_COUNT:
            return {
                ...state,
                wrongInputCount: state.wrongInputCount + 1
            }
        case PlayAction.RESET_WRONG_COUNT:
            return {
                ...state,
                wrongInputCount: 0
            }
        case PlayAction.ADD_PLAYED_WORD:
            return {
                ...state,
                playedWords: [...state.playedWords, action.payload.key]
            }
        case PlayAction.RESET_PLAYED_WORD:
            return {
                ...state,
                playedWords: []
            }
        case PlayAction.UPDATE_ACTIVE_ITEM:
            return {
                ...state,
                activeItem: state.listOfItems.length === state.activeItem - 1 ? 0 : state.activeItem + 1
            }
        case PlayAction.RESET_ACTIVE_ITEM:
            return {
                ...state,
                activeItem: 0
            }
        case PlayAction.REVEAL_ANSWER_NO:
            return {
                ...state,
                revealAnswer: false
            }
        case PlayAction.REVEAL_ANSWER_YES:
            return {
                ...state,
                revealAnswer: true
            }
        case PlayAction.REPLAY_GAME:
            return {
                ...initialState,
                listOfItems: fetchRandomizeCategoryItems(action.payload.category)
            }
        default:
            return state
    }
}

/**
 * Create our Store component to be wrapped around the main application.
 */
const PlayStore = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState)

    return (
        <PlayContext.Provider value={[state, dispatch]}>
            {children}
        </PlayContext.Provider>
    )
}

export const PlayContext = createContext(initialState)
export default PlayStore