import { useContext } from "react";
import { PlayContext } from "../../stores/PlayStore";

import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

const GuessArea = () => {

    const [PlayStore] = useContext(PlayContext)
    const {listOfItems, playedWords, activeItem, revealAnswer } = PlayStore;

    return ( <div>
        <Guess word={listOfItems?.[activeItem]} playedWords={playedWords} revealAnswer={revealAnswer}/>
    </div> );
}
 
export default GuessArea;

const Guess = ({word, playedWords, revealAnswer}) => {
    if(!word) return '';
    if(revealAnswer) return(
        <Fade duration={3000}>
            <h1 className="text-center font-monospace">
                {
                    Array.from(word).map((e, index) => (<LetterBox key={index} char={e} />))
                }
            </h1> 
        </Fade>
    )
    return (
        <h1 className="text-center font-monospace">
        {
            Array.from(word).map(
                (e, index) => {
                    let char = '_'
                    if (e === ' ') char = ' ';
                    else if (playedWords.includes(e) || revealAnswer) char = e;
                    return <LetterBox key={index} char={char} />
                }
            )
        }
    </h1>);
}
 
const LetterBox = ({char}) => {
    return(
        <span className={ `badge mx-1 mt-1 ${char === '_' ? 'bg-warning': 'bg-success'}` }>
            <Zoom duration={500} when={char !== '_'} >
                {char}
            </Zoom>
        </span>
    )
}