import { useContext } from "react";
import { Link } from "react-router-dom";
import { PlayAction, PlayContext } from "../stores/PlayStore";


const CategoryButton = ({ name }) => {
    return ( 
        <div className="card w-100 h-100">
        {/* <img src="..." className="card-img-top" alt="..." /> */}
        <div className="card-body">
            <h5  className="display-5 card-title text-center fancy-style">{name}</h5>
            <Link className="stretched-link" to={`/play/${name.toLowerCase()}`} /* onClick={linkClick} */>
                
            </Link>
        </div>
        </div>
    );
}
 
export default CategoryButton;