import React, { useContext } from 'react';

import { useMatch } from "react-router-dom";
import useSound from "use-sound";
import { GlobalContext } from '../stores/GlobalStore';
import { theme3, theme2, test } from "../utils/importer";


const useBgMusic = () => {

    const [{ music }] = useContext(GlobalContext)

    const [playMain, { stop: stopMain, sound: soundMain }] = useSound(theme2, { loop: true, interrupt: true })
    const [playGameBg, { stop: stopGameBg, sound: soundGameBg }] = useSound(theme3, { loop: true, interrupt: true })

    const match = useMatch('/play/:item');

    if (!music) {
        stopGameBg()
        stopMain()
    } else {
        if (match) {
            if (!soundMain?.playing()) playMain()
            stopGameBg()
        } else {
            if (!soundGameBg?.playing()) playGameBg()
            stopMain()
        }
    }
}

export default useBgMusic;